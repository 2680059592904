<template #default>
  <div
    class="TheLoader"
    @touchmove="removeScroll"
  >
    <div
      v-show="$store.state.isPxLoading"
      class="pxLoaderBar"
    />
    <div class="TheLoader__container">
      <div class="TheLoader__logo">
        <LogoBinalogueLoader />
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from "@vue/runtime-core";

export default {
  setup() {
    const gsap = inject("$gsap");
    const tlLoader = gsap.timeline({paused:true});
    const isTimelineComplete = true;

    return {
      tlLoader,
      isTimelineComplete
    };
  },
  watch: {
    "$store.state.isLoading": function () {
      // reset scroll on page change


      // check the inertia loading state
      if (this.$store.state.isLoading) {

        this.initLoader();
      } else {
        this.$store.commit("TOGGLE_IS_PX_LOADING", false);
        //this.$store.commit("TOGGLE_COVER", false);
        this.$store.commit('TOGGLE_BG_BACKGROUND_HOME', true);
        // document.getElementById('loaderCover').classList.remove('loader-cover');
        this.removeLoader();

      }
    },
  },

  mounted() {
    // this.handleHeight();
    //this.initLoader();

    /* eslint-disable */
    this.$gsap.to(".loader-dot", {opacity: 1,stagger: 0.15,repeat: -1, delay: 0.6,repeatDelay: 0.3});
    /* eslint-enable */

    /* eslint-disable */
    this.tlLoader.to(".TheLoader", {zIndex: 99,duration: 0, });
    this.tlLoader.to(".TheLoader", {opacity: 1,duration: 0.15, });
    this.tlLoader.to(".TheLoader__logo", {opacity: 1,duration: 0.2,delay: 0.15,ease: "power2.out", onComplete: this.completeTimeline});
    /* eslint-enable */
  },

  methods: {
    handleHeight() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },

    removeScroll(e) {
      e.preventDefault();
    },

    initLoader() {
      this.isTimelineComplete = false;
      this.tlLoader.play();
      //this.$store.commit("TOGGLE_COVER", true);
      //document.getElementById('loaderCover').classList.add('loader-cover');
    },

    removeLoader() {

      if(this.isTimelineComplete) {
        this.tlLoader.to({},{},'+=3').reverse();
        this.isTimelineComplete = false;
      } else {
        setTimeout(() => {
          this.removeLoader();
        }, 100);
      }
    },

    completeTimeline() {
      this.isTimelineComplete = true;
    },
  }
};
</script>

<style lang="scss">
.TheLoader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -3;
  display: flex;
  overflow: visible;
  background: $black;
  opacity: 0;
  width: 100vw;
  height: 105vh;
  //height: calc(var(--vh, 1vh) * 100);

  &__container {
    position: relative;
    top: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    // background: $black;
    // background: rgba(red, 0.5);
    transform: translateY(-50%);
    width: 100%;
    height: 1500px;
  }

  &__logo {
    max-width: 280px;
    margin-left: 16px;
    opacity: 0;
    width: 90%;
    // transform: translateY(60px);
    svg {
      width: 100%;
      height: auto;

      @include tablet {
        max-width: 400px;
      }
    }
  }

  .pxLoaderBar {
    position: fixed;
    top: 0;
    left: 0;
    background: $orange;
    width: 100%;
    height: 3px;
  }

  .loader-dot {
    opacity: 0;
  }
}
</style>
