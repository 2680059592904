<template>
  <div
    id="header"
    class="TheHeader"
    :class="{hideHeader: $store.state.isModalVideo}"
  >
    <div
      class="showBgHeader"
      :style="{backgroundColor: `${varHeaderBg}`}"
    />
    <section class="TheHeader__main">
      <InertiaLink
        title="binalogue"
        class="TheHeader__logo"
        :href="route('homepage')"
        @click="checkMenu"
      >
        <LogoBinalogueWhite />
        <span class="hidden">Binalogue</span>
      </InertiaLink>
      <div class="TheHeader__buttons">
        <InertiaLink
          class="TheHeader__buttons--link regular-text toggleMenuItems"
          href="/work/"
        >
          <span class="name">Work</span><span class="dot">.</span>
        </InertiaLink>
        <InertiaLink
          class="TheHeader__buttons--link regular-text white toggleMenuItems"
          href="/about/"
        >
          <span class="name">About</span><span class="dot">.</span>
        </InertiaLink>
        <!-- v-show="$page.props.request.route_name !== 'search'" -->
        <button
          title="Search"
          class="TheHeader__search"
          @click="toggleSearch"
        >
          <span class="IconSearch">
            <IconSearch />
          </span>


          <div class="cross IconCross">
            <IconClose />
          </div>
        </button>
        <button
          title="Menu"
          class="TheHeader__burger"
          @click="toggleMenu"
        >
          <div class="burger">
            <span class="toggleBurger" />
            <span class="toggleBurger" />
            <span class="toggleBurger" />
          </div>

          <!-- <div class="cross">
            <IconClose />
          </div> -->
        </button>
      </div>
    </section>
    <TheMenu />
    <TheSearchIndex />
    <div v-if="$page.props.content">
      <a
        v-if="$page.props.auth.user && $page.url.includes(`/work/${$page.props.content.slug}`)"
        :href="`https://binalogue.com/cp/collections/projects/entries/${$page.props.content.id}`"
        target="_blank"
        class="user-edit"
      >
        Edit

      <!-- {{ $page.props }} -->
      </a>

      <a
        v-if="$page.props.auth.user && $page.url.includes(`/press/${$page.props.content.slug}`)"
        :href="`https://binalogue.com/cp/collections/news/entries/${$page.props.content.id}`"
        target="_blank"
        class="user-edit"
      >
        Edit

      <!-- {{ $page.props }} -->
      </a>

      <!-- TODO:delete -->
      <a
        v-if="$page.props.auth.user && $page.url.includes(`/new-press/${$page.props.content.slug}`)"
        :href="`https://binalogue.io/cp/collections/news/entries/${$page.props.content.id}`"
        target="_blank"
        class="user-edit"
      >
        Edit

      <!-- {{ $page.props }} -->
      </a>
    </div>
  </div>
</template>

<script>
/* Vendor */
import { InertiaLink } from "@inertiajs/inertia-vue3";
import { inject } from "@vue/runtime-core";

export default {
  components: {
    InertiaLink
  },

  setup() {
    const gsap = inject("$gsap");
    const tlHeader = gsap.timeline();
    const tlSearch = gsap.timeline();

    return {
      tlHeader,
      tlSearch
    };
  },
  data() {
    return {
      showMenu: false,
      showSearch: false,
      keepInitialColor: null
    };
  },

  computed: {
    mainNavbar() {
      return this.$page.props.main_navbar;
    },
    varHeaderBg() {
      if (this.$store.state.menuColor === "black") {
        return "white";
      } else {
        return "black";
      }
    }
  },

  watch: {
    "$store.state.toSearchHidden"(value) {
      // reset scroll on page change
      if (value) {
        this.leaveSearch();
      }
    }
  },

  methods: {
    toggleMenu() {
      if (this.showSearch) {
        this.leaveSearch();
      }

      if (this.$store.state.isModalContactForm) {
        this.$store.commit("TOGGLE_IS_MODAL_CONTACT_FORM", false);
        this.$store.commit("TOGGLE_IS_PAUSE_SCROLL", false);
      }

      if (this.showMenu) {
        this.leaveMenu();
        this.$store.commit("TOGGLE_COVER", false);
      } else {
        this.enterMenu();
        this.$store.commit("TOGGLE_COVER", true);
      }

      this.$store.commit("TOGGLE_IS_MODAL_VIDEO", false);
    },

    enterMenu() {
      this.showMenu = true;
      /* eslint-disable */
      this.$store.commit("TOGGLE_IS_PAUSE_SCROLL", true);
      this.tlHeader.restart();
      this.tlHeader.to(
        ".TheMenu",
        { y: 0, opacity: 1, ease: "power2.inOut", duration: 0.4 },
        0
      );
      this.tlHeader.to(
        ".burger span:nth-child(2)",
        { opacity: 0, duration: 0.2 },
        0.3
      );
      this.tlHeader.to(
        ".burger span:nth-child(1)",
        {
          rotation: 45,
          width: "105%",
          transformOrigin: "left 50%",
          duration: 0.2
        },
        0.3
      );
      this.tlHeader.to(
        ".burger span:nth-child(3)",
        {
          rotation: -45,
          width: "105%",
          transformOrigin: "0 50%",
          duration: 0.2
        },
        0.3
      );
      this.tlHeader.to(
        ".TheMenu__links",
        { opacity: 1, y: 0, ease: "power2", duration: 0.3 },
        0.4
      );
      this.tlHeader.to(
        ".TheMenu__rrss",
        { opacity: 1, y: 0, ease: "power2", duration: 0.3 },
        0.4
      );

      this.keepInitialColor = this.$store.state.menuColor;
      this.$gsap.to(".toggleLogo", {
        fill: "white",
        delay: 0.2,
        duration: 0.3
      });
      this.$gsap.to(".toggleMenuItems", {
        color: "white",
        delay: 0.2,
        duration: 0.2
      });
      this.$gsap.to(".toggleBurger", {
        background: "white",
        delay: 0.2,
        duration: 0.3
      });
      this.$gsap.to(".showBgHeader", { opacity: 0, duration: 0.3 });
      /* eslint-enable */
    },

    leaveMenu() {
      this.tlHeader.reverse();
      this.showMenu = false;
      this.$store.commit("TOGGLE_IS_PAUSE_SCROLL", false);
      this.$store.state.menuColor = this.keepInitialColor;
      /* eslint-disable */
      this.$gsap.to(".toggleLogo", {
        fill: this.$store.state.menuColor,
        delay: 0.2,
        duration: 0.3
      });
      this.$gsap.to(".toggleMenuItems", {
        color: this.$store.state.menuColor,
        delay: 0.2,
        duration: 0.2
      });
      this.$gsap.to(".toggleBurger", {
        background: this.$store.state.menuColor,
        delay: 0.2,
        duration: 0.3
      });
      this.$gsap.to(".showBgHeader", { opacity: 1, delay: 0.3, duration: 0.3 });
      /* eslint-enable */
    },

    toggleSearch() {
      if (this.showMenu) {
        this.leaveMenu();
      }

      if (this.$store.state.isModalContactForm) {
        this.$store.commit("TOGGLE_IS_MODAL_CONTACT_FORM", false);
        this.$store.commit("TOGGLE_IS_PAUSE_SCROLL", false);
      }

      if (this.showSearch) {
        this.leaveSearch();
      } else {
        this.enterSearch();
      }

      this.$store.commit("TOGGLE_IS_MODAL_VIDEO", false);
    },

    enterSearch() {
      this.showSearch = true;
      this.$store.state.isSearchActive = true;
      this.$store.commit("TOGGLE_IS_PAUSE_SCROLL", true);
      this.tlSearch.restart();
      /* eslint-disable */
      this.tlSearch.to(
        ".TheSearchIndex",
        { display: "block", ease: "power2.inOut", duration: 0 },
        0
      );
      this.tlSearch.to(
        ".TheSearchIndex",
        { display: "flex", ease: "power2.inOut", duration: 0 },
        0.1
      );
      this.tlSearch.to(
        ".TheSearchIndex",
        { zIndex: 4, opacity: 1, ease: "power2.inOut", duration: 0.4 },
        0.1
      );
      this.tlSearch.to(".IconSearch", { opacity: 0, duration: 0.2 }, 0);
      this.tlSearch.to(".IconCross", { opacity: 1, duration: 0.2 }, 0.5);

      this.keepInitialColor = this.$store.state.menuColor;
      this.$store.state.menuColor = "black";
      this.$gsap.to(".toggleLogo", {
        fill: "black",
        delay: 0.2,
        duration: 0.3
      });
      this.$gsap.to(".toggleMenuItems", {
        color: "black",
        delay: 0.2,
        duration: 0.2
      });
      this.$gsap.to(".toggleBurger", {
        background: "black",
        delay: 0.2,
        duration: 0.3
      });
      this.$gsap.to(".showBgHeader", { opacity: 0, delay: 0.2, duration: 0.3 });
      /* eslint-enable */
    },

    leaveSearch() {
      this.tlSearch.reverse();
      this.showSearch = false;
      this.$store.state.menuColor = this.keepInitialColor;
      this.$store.commit("TOGGLE_IS_PAUSE_SCROLL", false);
      this.$store.commit("TOGGLE_SEARCH_HIDDEN", false);

      /* eslint-disable */
      this.$gsap.to(".toggleLogo", {
        fill: this.$store.state.menuColor,
        delay: 0.1,
        duration: 0.3
      });
      this.$gsap.to(".toggleMenuItems", {
        color: this.$store.state.menuColor,
        delay: 0.1,
        duration: 0.2
      });
      this.$gsap.to(".toggleBurger", {
        background: this.$store.state.menuColor,
        delay: 0.2,
        duration: 0.3
      });
      this.$gsap.to(".showBgHeader", { opacity: 1, delay: 0.3, duration: 0.3 });
      /* eslint-enable */

      setTimeout(() => {
        this.$store.state.isSearchActive = false;
      }, 600);
    },

    checkMenu() {
      if (this.showMenu) {
        this.leaveMenu();
      }

      if (this.$store.state.isModalContactForm) {
        this.$store.commit("TOGGLE_IS_MODAL_CONTACT_FORM", false);
        this.$store.commit("TOGGLE_IS_PAUSE_SCROLL", false);
      }

      if (this.showSearch) {
        this.leaveSearch();
      }

      this.$store.commit("TOGGLE_IS_MODAL_VIDEO", false);
    }
  }
};
</script>

<style lang="scss">
.TheHeader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  margin-top: -2px;

  @include tablet {
    padding-top: 0.7vw;
    margin-top: 0;
  }

  .showBgHeader {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    width: 100%;
    height: -1%;
  }

  &.hideHeader {
    z-index: 0;
    opacity: 0;
  }

  &__main {
    position: relative;
    z-index: 11;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 12px;
    padding-bottom: 5px;
    width: 100%;

    @include paddingMobile;

    @include tablet {
      padding-top: 20px;
      padding-right: 5vw;
      padding-bottom: 12px;
      padding-left: 5vw;
    }

    @include desktop-m {
      padding-top: 25px;
      padding-right: 5vw;
      padding-bottom: 14px;
      padding-left: 5vw;
    }
  }

  &__logo {
    margin-top: 8px;
    svg {
      height: 22px;
      width: auto;

      @include tablet {
        height: 32px;
      }

      @include desktop-xl {
        height: 48px;
      }
    }

    @include tablet {
      margin-top: 2px;
    }
  }

  &__buttons {
    position: relative;
    top: -4px;
    display: flex;
    align-items: flex-end;

    &--link {
      display: none;

      @include tablet {
        display: inline-block;
        margin-right: 20px;

        .dot {
          color: $orange;
        }

        &:hover {
          .name {
            color: $orange;
          }
          .dot {
            color: $white;
          }
        }
      }

      @include desktop-xl {
        line-height: 1;
      }
    }
  }

  &__search {
    position: relative;
    padding: 7px 4px;

    @include tablet {
      padding: 5px;
    }

    @include desktop-xl {
      padding: 0;
    }

    svg {
      fill: $white;
      height: 25px;
      width: auto;

      @include tablet {
        height: 27px;
      }

      @include desktop-xl {
        height: 37px;
      }
    }

    .IconSearch {
      svg {
        position: relative;
        top: 2px;
        height: 25px;

        @include tablet {
          height: 27px;
        }

        @include desktop-xl {
          height: 37px;
          margin-right: 10px;
        }
      }
    }
  }

  &__burger {
    position: relative;
    width: 40px;
    height: 40px;
    padding: 6.5px;
    margin-left: 10px;

    @include tablet {
      padding: 5px;
    }

    @include desktop-xl {
      padding: 0;
    }

    .burger {
      display: flex;
      height: 23px;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-between;

      @include tablet {
        height: 25px;
      }

      @include desktop-xl {
        height: 33px;
      }

      span {
        display: block;
        width: 100%;
        height: 3px;
      }
    }
  }

  .cross {
    position: absolute;
    top: 10px;
    opacity: 0;

    @include tablet {
      top: 7px;
    }

    @include desktop-xl {
      top: 0;
    }

    svg {
      height: 24px;
      width: auto;
      fill: $black;

      @include tablet {
        height: 26px;
      }

      @include desktop-xl {
        height: 38px;
      }
    }
  }

  .cross-search {
    position: relative;
    top: 4px;
    opacity: 1;

    svg {
      height: 24px;
      width: auto;
      fill: $black;

      @include tablet {
        height: 26px;
      }
    }
  }
}
</style>
