<template>
  <div id="single-project-wrapper">
    <div
      id="single-project-content"
      class="SingleProject single-project-scroller "
    >
      <div
        v-if="$store.state.isMobile && content.top_bar_mobile"
        :style="`background : ${customColor};`"
        class="verticalSpaceMobile mobile-top-scroll"
      />

      <div
        v-if="content.top_bar_desktop && !$store.state.isMobile"
        :style="`background : ${customColor};`"
        class="verticalSpaceDesktop desktop-top-scroll"
      />
      <section
        v-for="(bardItem, index) in content.content_bard"
        :key="`bardItem${index}`"
        class="SingleProject__section"
      >
        <div v-if="bardItem.type === 'set'">
          <div
            v-if="bardItem.attrs.enabled !== false || $page.props.auth.user"
            :class="{outline: bardItem.attrs.enabled === false && $page.props.auth.user}"
          >
            <div v-if="bardItem.attrs.values.type === 'project_metadata'">
              <component
                :is="bardItem.attrs.values.type"
                :content="content"
                :content-metadata="bardItem.attrs.values"
                :is-project="true"
                :position="index"
              />
            </div>
            <component
              :is="bardItem.attrs.values.type"
              v-else
              :content="bardItem.attrs.values"
              :is-project="true"
              :position="index"
            />
          </div>
        </div>
      </section>

      <span class="project-black-menu" />

      <section
        v-if="content.project_credits.length > 0"
        class="Credits project-credits scroll-credits"
      >
        <h3 class="section-title project-section-title">
          Credits<span class="orange">.</span>
        </h3>
        <div class="Credits__container">
          <div
            v-for="(credit, i) in content.project_credits"
            :key="`credit${i}`"
            class="Credits__item"
            :class="{ itemSub: credit.subsection }"
          >
            <h3
              :class="{
                'medium-text-bold': credit.subsection,
                'main-text-bold': !credit.subsection,
              }"
            >
              {{ credit.credit }}
            </h3>
            <div
              v-if="credit.include_contacts"
              class="regular-text grey"
            >
              <div
                v-for="(contact, idx) in credit.credit_contacts"
                :key="`contact${idx}`"
              >
                <a

                  v-if="contact.external_link"
                  class="regular-text grey block"
                  :href="contact.external_link"
                  target="_blank"
                >
                  {{ contact.title }}
                </a>
                <p
                  v-else
                  class="regular-text grey block"
                >
                  {{ contact.title }}
                </p>
              </div>
            </div>
            <p
              v-else
              class="regular-text grey credits-mark"
              v-html="markdownToHtml(credit.content)"
            />
          </div>
        </div>
      </section>

      <div
        v-if="content.project_credits.length > 0 && !content.associated_projects"
        class="SingleProject__additional-space"
      />

      <section
        v-if="content.associated_projects"
        class="Related project-related scroll-associated"
      >
        <h3 class="section-title project-section-title">
          Related projects & news<span class="orange">.</span>
        </h3>
        <div class="Related__container">
          <div
            v-for="(associatedProject, index) in content.associated_projects"
            :key="`associatedProject${index}`"
            class="Related__item"
          >
            <InertiaLink
              v-if="associatedProject.collection === 'projects'"
              class="Related__item--link"
              :href="`/work/${associatedProject.slug}/`"
            >
              <img
                v-if="associatedProject.square_thumbnail"
                :data-src="getImageKitURL(associatedProject.square_thumbnail, 3, associatedProject.image_type)"
                :src="getImageKitURL(associatedProject.square_thumbnail, 3, associatedProject.image_type,true)"
                :alt="associatedProject.title"
                class="Related__item--image"
              >
              <img
                v-else
                :src="`${$store.state.baseimageUrl}default.png`"
                class="Related__item--image"
                alt="default image"
              >
              <h5 class="indexProject__client mobile-padding">
                {{ associatedProject.client_subtitle }}
              </h5>
              <h3 class="small-title grey-dark mobile-padding">
                {{ associatedProject.title }}
              </h3>
              <h4 class="regular-text orange mobile-padding">
                {{ associatedProject.subtitle }}
              </h4>
            </InertiaLink>
            <InertiaLink
              v-else
              class="Related__item--link"
              :href="`/press/${associatedProject.slug}/`"
            >
              <img
                v-if="associatedProject.square_thumbnail"
                :data-src="getImageKitURL(associatedProject.square_thumbnail, 3, associatedProject.image_type)"
                :src="getImageKitURL(associatedProject.square_thumbnail, 3, associatedProject.image_type,true)"
                :alt="associatedProject.title"
                class="Related__item--image"
              >
              <img
                v-else
                :src="`${$store.state.baseimageUrl}default.png`"
                class="Related__item--image"
                alt="default image"
              >
              <h5 class="indexProject__client mobile-padding">
                Press
              </h5>
              <h3 class="small-title grey-dark mobile-padding">
                {{ associatedProject.title }}
              </h3>
              <h4
                v-if="associatedProject.news_filters"
                class="regular-text orange mobile-padding"
              >
                <span
                  v-for="(category, idx) in customCategory(associatedProject.news_filters)"
                  :key="category"
                >
                  <span v-if="idx > 0">, </span>
                  {{ category }}
                </span>
              </h4>
            </InertiaLink>
          </div>
        </div>
      </section>
      <span id="single-project-content-footer" />
      <TheFooter />
    </div>


    <span
      class="cursor cursorPlay"
    >
      <TextPlay />
    </span>
    <span
      class="cursor cursorPrev"
    >
      <TextPrev />
    </span>
    <span
      class="cursor cursorNext"
    >
      <TextNext />
    </span>
  </div>
</template>

<script>
/* Vendor */
import { nextTick } from "vue";
import { InertiaLink } from "@inertiajs/inertia-vue3";

/* Helpers */
import { mainLayout } from "@/helpers/vue-layouts";
import seo from "@/helpers/seo";

/* Mixins */
import page from "@/mixins/page";
import marked from "@/mixins/marked";
import scrollGlobal from "@/mixins/scroll-global";
import imageKit from "@/mixins/image-kit";
import loader from "@/mixins/loader";

export default {
  components: {
    InertiaLink
  },

  mixins: [imageKit, page, marked, scrollGlobal, loader],

  layout: mainLayout,

  props: {
    content: {
      type: Object,
      default: () => {}
    },

    awards: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      // scroll animations
      headerColorInit: null,
      bgScrollAnimation: null
    };
  },

  computed: {
    customColor() {
      if (this.content.bg_top_bar) {
        return this.content.bg_top_bar;
      } else {
        return "transparent";
      }
    }
  },

  created() {
    seo.seoContent(this.content);
  },

  mounted() {
    this.$nextTick(() => {
      //console.log('init images' );
      this.imagesToPreload = document.querySelectorAll("img");
      // console.log('home page imgs',this.imagesToPreload  );

      this.getBackgroundImagesUrl();
      this.loadImages();
    });

    //check if we have to set the cursor animation
    if (!this.$store.state.isMobile) {
      const bardItems = [];

      for (let i = 0; i < this.content.content_bard?.length; i++) {
        const element = this.content.content_bard[i];
        if (element.type === "set") {
          bardItems.push(element.attrs.values.type);
        }
      }

      if (bardItems.includes("video_pop_up")) {
        //console.log("video_pop_up");
        this.cursorAnimations();
      }
      if (bardItems.includes("project_slider")) {
        this.cursorAnimationsSlider();
      }
    }
  },

  beforeUnmount() {
    this.bgScrollAnimation.scrollTrigger.kill();
  },

  methods: {
    customCategory(categories) {
      let returnCats = [];
      for (let i = 0; i < categories.length; i++) {
        const cat = categories[i];
        switch (cat) {
          case "awards":
            returnCats.push("Awards");
            break;
          case "news":
            returnCats.push("News");
            break;
          case "project-feature":
            returnCats.push("Project Feature");
            break;
          case "talk":
            returnCats.push("Talk");
            break;
          case "interview":
            returnCats.push("Interview");
            break;
          default:
            returnCats.push(cat);
        }
      }

      return returnCats;
    },

    cursorAnimations() {
      //cursor animation
      this.$gsap.set(".cursorPlay", { xPercent: -50, yPercent: -50 });

      const play = document.querySelector(".cursorPlay");
      const pos = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
      const mouse = { x: pos.x, y: pos.y };
      const speed = 0.3;

      const fpms = 60 / 1000;

      const xSet = this.$gsap.quickSetter(play, "x", "px");
      const ySet = this.$gsap.quickSetter(play, "y", "px");

      window.addEventListener("mousemove", (e) => {
        mouse.x = e.x;
        mouse.y = e.y;
      });

      this.$gsap.ticker.add((time, deltaTime) => {
        const delta = deltaTime * fpms;
        const dt = 1.0 - Math.pow(1.0 - speed, delta);

        pos.x += (mouse.x - pos.x) * dt;
        pos.y += (mouse.y - pos.y) * dt;
        xSet(pos.x);
        ySet(pos.y);
      });
    },

    cursorAnimationsSlider() {
      //cursor animation
      this.$gsap.set(".cursorPrev", { xPercent: -50, yPercent: -50 });
      this.$gsap.set(".cursorNext", { xPercent: -50, yPercent: -50 });

      const prev = document.querySelector(".cursorPrev");
      const next = document.querySelector(".cursorNext");
      const pos = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
      const mouse = { x: pos.x, y: pos.y };
      const speed = 0.3;

      const fpms = 60 / 1000;

      const xSet = this.$gsap.quickSetter(prev, "x", "px");
      const ySet = this.$gsap.quickSetter(prev, "y", "px");
      const xSetNext = this.$gsap.quickSetter(next, "x", "px");
      const ySetNext = this.$gsap.quickSetter(next, "y", "px");

      window.addEventListener("mousemove", (e) => {
        mouse.x = e.x;
        mouse.y = e.y;
      });

      this.$gsap.ticker.add((time, deltaTime) => {
        const delta = deltaTime * fpms;
        const dt = 1.0 - Math.pow(1.0 - speed, delta);

        pos.x += (mouse.x - pos.x) * dt;
        pos.y += (mouse.y - pos.y) * dt;
        xSet(pos.x);
        ySet(pos.y);
        xSetNext(pos.x);
        ySetNext(pos.y);
      });
    },

    init() {
      if (this.$store.state.isMobile) {
        if (this.content.menu_white_mobile) {
          this.toggleMenuColor("white", 0, 0);
        } else {
          this.toggleMenuColor("black", 0, 0);
        }
      } else {
        if (this.content.menu_white) {
          this.toggleMenuColor("white", 0, 0);
        } else {
          this.toggleMenuColor("black", 0, 0);
        }
      }

      if (this.$store.state.isMobile && !this.content.transparent_header) {
        this.$gsap.to(".showBgHeader", { height: "100%", duration: 0 });
      }

      setTimeout(() => {
        this.setScroller("#single-project-wrapper", "#single-project-content");
        // this.$store.commit("TOGGLE_IS_PROJECT_LOADING", false);

        if (!this.$store.state.isMobile) {
          if (this.$store.state.anchorName !== "") {
            this.goToScroll(this.$store.state.anchorName);
          }
        }

        // HEADER ANIMATIONS
        this.bgScrollAnimation = this.$gsap.to(".showBgHeader", {
          scrollTrigger: {
            trigger: "#single-project-content",
            markers: false,
            onEnter: this.updateHeaderState,
            onEnterBack: this.updateHeaderStateBack,
            start: "50px top",
            end: "top bottom",
            toggleActions: "play none reverse none"
          },
          height: "100%",
          duration: 0.2,
          ease: "Power2.out"
        });
      }, 400);
    },

    updateHeaderState() {
      if (this.$store.state.isMobile) {
        if (this.content.logo_white_mb_on_scroll) {
          this.toggleMenuColor("white", 0.2, 0);
        } else {
          this.toggleMenuColor("black", 0.2, 0);
        }
      } else {
        if (this.content.logo_white_on_scroll) {
          this.toggleMenuColor("white", 0.2, 0);
        } else {
          this.toggleMenuColor("black", 0.2, 0);
        }
      }
    },

    updateHeaderStateBack() {
      if (this.$store.state.isMobile) {
        if (this.content.menu_white_mobile) {
          this.toggleMenuColor("white", 0.2, 0);
        } else {
          this.toggleMenuColor("black", 0.2, 0);
        }
      } else {
        if (this.content.menu_white) {
          this.toggleMenuColor("white", 0.2, 0.4);
        } else {
          this.toggleMenuColor("black", 0.2, 0.4);
        }
      }
    }
  }
};
</script>

<style lang="scss">
.SingleProject {
  width: 100vw;
  max-width: 100vw;
  // overflow-x: hidden;
  @include headerMargin;

  &__section {
    max-width: 100vw;
    margin: -2px 0;
    //overflow-x: hidden;
    //overflow-y: visible;
  }

  &__additional-space {
    width: 100%;
    height: 5vw;
  }

  .Credits {
    width: 100%;

    .project-section-title {
      padding-top: 13vw;
      padding-bottom: 3vw;

      @include tablet {
        padding-top: 7.7vw;
        padding-bottom: 1vw;
      }
    }

    &__container {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;

      @include paddingMobile;

      @include tablet {
        @include paddingBig;
      }
    }

    &__item {
      flex-grow: 0;
      margin-right: 4%;
      margin-bottom: 15px;
      width: 46%;

      &.itemSub {
        width: 100%;
        margin-top: 4vw;

        @include tablet {
          margin-top: 2vw;
        }
      }

      &--text {
        display: flex;
        flex-direction: column;
      }

      @include tablet {
        width: 31.1%;
        margin-right: 1%;
        margin-bottom: 24px;
      }
    }
  }

  .Related {
    width: 100%;

    .project-section-title {
      padding-top: 13vw;
      padding-bottom: 5vw;

      @include tablet {
        padding-top: 7.7vw;
        padding-bottom: 2vw;
      }
    }

    &__container {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      @include paddingBig;
    }

    &__item {
      flex-grow: 0;
      width: 100%;
      @include marginBottomSmall;

      &:nth-child(3n) {
        margin-right: 0;
      }

      &--image {
        width: 100%;
        margin-bottom: 10px;
      }

      @include tablet {
        margin-right: 2.33%;
        width: 31.66%;

        &:nth-child(3n) {
          margin-right: 0;
        }
      }
    }

    a {
      color: $grey;

      @include transition;

      &:hover {
        color: $orange;
      }
    }
  }
}

// cursor options
.cursorPlay,
.cursorPrev,
.cursorNext {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  display: none;
  pointer-events: none;
  opacity: 0;

  @include tablet {
    display: block;
  }

  svg {
    opacity: 1;
    fill: $white;
    height: 40px;
    width: auto;
  }

  &.black-true {
    svg {
      fill: $black;
    }
  }
}
</style>
