<template>
  <h3
    :id="`title-${dynamicNumber}`"
    class="section-title ProjectSectionTitle"
    :class="[`small-${content.small_bottom_margin}`,content.mobile_settings ]"
    :style="`color : ${customDescColor};background : ${customColor}; ${customGradient} `"
  >
    {{ content.section_title }}<span
      class="orange"
      :class="{hide: content.remove_dot}"
    >.</span> <br>
    <span v-if="content.section_subtitle">{{ content.section_subtitle }}<span
      v-if="content.include_dot"
      class="orange"
    >.</span> </span>
  </h3>
</template>

<script>
/* Mixins */
import marked from "@/mixins/marked";

export default {
  mixins: [marked],
  props: {
    content: {
      type: Object,
      default: () => {}
    },
    isProject: {
      type: Boolean
    }
  },

  computed: {
    dynamicNumber() {
      const randomNum = Math.floor(1000 + Math.random() * 9000);
      return randomNum;
    },

    customColor() {
      if (this.content.bg_color) {
        return this.content.bg_color;
      } else {
        return "transparent";
      }
    },

    customGradient() {
      if (this.content.gradient_code) {
        return this.content.gradient_code;
      } else {
        return "";
      }
    },

    customDescColor() {
      if (this.content.color) {
        return this.content.color;
      } else {
        return "#000";
      }
    }
  },
};
</script>

<style lang="scss">
.ProjectSectionTitle {
  max-width: 100vw;
  padding-top: 13vw;
  padding-bottom: 5vw;
  overflow-x: hidden;
  width: 100%;

  .hide {
    display: none;
  }

  strong {
    color: $orange;
  }

  @include tablet {
    padding-top: 7.7vw;
    padding-bottom: 1.5vw;
    @include paddingBig;
  }

  &.small-true {
    padding-bottom: 2vw;

    @include tablet {
      padding-bottom: 0.3vw;
    }
  }

  &.hide-mobile {
  display: none;

  @include tablet {
    display: block;
  }
}



}
</style>
