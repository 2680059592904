<template>
  <div
    :id="`one-column-${dynamicNumber}`"
    class="oneColumn"
    :style="`background : ${customColor}; ${customGradient}`"
    :class="[content.horizontal_margin, content.vertical_margin,column.mobile_settings]"
  >
    <div
      v-if="column.content === 'image' || column.content === 'lossless-image' || column.content === 'original-image' || column.content === 'hq-image'"
      :style="` width: ${customWidth}; ${customPadding} `"
      class="oneColumn__image"
    >
      <img
        v-if="$store.state.isMobile && column.alternate_content && column.mobile_image"
        :data-src="getImageKitURL(column.mobile_image, 1, column.content,false,position)"
        :src="getImageKitURL(column.mobile_image, 1, column.content,true,position)"
        :alt="column.mobile_image"
        class="oneColumn__image--item"
      >
      <img
        v-else-if="column.image"
        :data-src="getImageKitURL(column.image, 1, column.content,false,position)"
        :src="getImageKitURL(column.image, 1, column.content,true,position)"
        :alt="column.image"
        class="oneColumn__image--item"
      >

      <!-- <img
        v-if="!$store.state.isMobile && column.image"
        :data-src="getImageKitURL(column.image, 1, column.content,false,position)"
        :src="getImageKitURL(column.image, 1, column.content,true,position)"
        :alt="column.image.alt"
        class="oneColumn__image--item"
      > -->
    </div>

    <div
      v-if="column.content === 'description'"
      class="main-text oneColumn__description"
      :style="`color : ${customDescColor}; width: ${content.range}% `"
      :class="column.description_padding"
      v-html="markdownToHtml(column.description)"
    />

    <div
      v-if="column.content === 'custom-description'"
      :style="`color : ${customDescColor}; width: ${content.range}% `"
      :class="column.description_padding"
    >
      {{ column }}
    </div>

    <div
      v-if="column.content === 'bg-video'"
      :style="` width: ${content.range}% `"
      class="oneColumn__video"
    >
      <!-- {{ content }} -->

      <backgroundVideo
        v-if="$store.state.isMobile && column.alternate_content && column.mobile_vimeo_id"
        :content="column"
        :vimeo_id="column.mobile_vimeo_id"
        :vimeo_ratio="column.mobile_vimeo_ratio"
        :content-width="content.range"
      />

      <backgroundVideo
        v-else-if="column.vimeo_id"
        :vimeo_id="column.vimeo_id"
        :vimeo_ratio="column.vimeo_ratio"
        :content-width="content.range"
      />
    </div>

    <div
      v-if="column.content === 'html-video'"
      :style="` width: ${content.range}% `"
      class="oneColumn__video"
    >
      <htmlVideo
        v-if="$store.state.isMobile && column.alternate_content && column.html_video_url_mb"
        :html-url="column.html_video_url_mb"
        :html-ratio="column.html_ratio_mb"
      />
      <htmlVideo
        v-else
        :html-url="column.html_video_url"
        :html-ratio="column.html_ratio"
      />
    </div>

    <div
      v-if="column.content === 'pop-up-video'"
      :style="` width: ${customWidth}; ${customPadding} `"
      class="oneColumn__pop"
      @click="shoModalVideo"
    >
      <!-- {{column}} -->
      <img
        v-if="column.pop_up_image"
        :data-src="getImageKitURL(column.pop_up_image, 1, column.content,false,position)"
        :src="getImageKitURL(column.pop_up_image, 1, column.content,true,position)"
        :alt="column.pop_up_image.alt"
        class="oneColumn__pop--image"
      >
      <img
        class="oneColumn__pop--play"
        src="/fonts/play_white.png"
        alt="indicators"
      >
      <img
        v-if="column.pop_up_rollover"
        :style="` ${customPadding} `"
        :data-src="getImageKitURL(column.pop_up_rollover, 1, column.content,false,position)"
        :src="getImageKitURL(column.pop_up_rollover, 1, column.content,true,position)"
        :alt="column.pop_up_rollover.alt"
        class="oneColumn__pop--rollover"
      >
    </div>
  </div>
</template>

<script>
/* Mixins */
import marked from "@/mixins/marked";
import imageKit from "@/mixins/image-kit";

export default {
  mixins: [marked, imageKit],
  props: {
    position:{
      type: Number,
      default: 1000
    },
    content: {
      type: Object,
      default: () => {}
    },
    isProject: {
      type: Boolean
    }
  },

  data() {
    return {
      showVideoModal: false,
      // mouse
      mouse: {},
    }
  },

  computed: {
    dynamicNumber() {
      const randomNum = Math.floor(1000 + Math.random() * 9000);
      return randomNum;
    },

    customColor() {
      if (this.content.bg_color) {
        return this.content.bg_color;
      } else {
        return "transparent";
      }
    },

    customGradient() {
      if (this.content.gradient_code) {
        return this.content.gradient_code;
      } else {
        return "";
      }
    },

    customPadding() {
      if (this.$store.state.isMobile) {
        return "padding:0";
      } else if (this.column.padding_settings === "img-pd-left") {
        return `padding-left : ${this.column.padding_width}vw`;
      } else if (this.column.padding_settings === "img-pd-right") {
        return `padding-right : ${this.column.padding_width}vw`;
      } else if (this.column.padding_settings === "img-pd-top") {
        return `padding-top : ${this.column.padding_width}vw`;
      } else if (this.column.padding_settings === "img-pd-bottom") {
        return `padding-bottom : ${this.column.padding_width}vw`;
      } else {
        return "padding:0";
      }
    },

    customDescColor() {
      if (this.column.description_color) {
        return this.column.description_color;
      } else {
        return "#000";
      }
    },

    customWidth() {
      if (this.$store.state.isMobile) {
        return "100%";
      } else {
        return this.content.range + "%";
      }
    },

    column() {
      return this.content.column[0];
    }
  },

  methods: {
    shoModalVideo() {
      this.$store.state.video_id = this.column.pop_up_vimeo_id;;
      this.$store.state.video_ratio = this.column.pop_up_video_ratio;
      if(this.showVideoModal){
        setTimeout(() => {
          // this.showVideoModal = false;
          this.$store.commit("TOGGLE_IS_MODAL_VIDEO", false);
        }, 800);
      }else {
        this.$store.commit("TOGGLE_IS_MODAL_VIDEO", true);
      }

      window.history.pushState({id:1}, null, null);
    },
  },
};
</script>

<style lang="scss">
.oneColumn {
  display: flex;
  justify-content: center;
  //max-width: 100vw;
  margin: 0 auto;
  //overflow-x: hidden;
  width: 100%;

  &.super {
    @include paddingSuper;
  }

  &.big {
    @include paddingBig;
  }

  &.standard {
    @include paddingMedium;
  }

  &.small {
    @include paddingSmall;
  }

  &.bottom-text {
    @include paddingMobileBottom;

    @include tablet {
      @include paddingBottomSmall;
    }
  }

  &.bottom-standard {
    @include paddingMobileBottom;

    @include tablet {
      @include paddingBottom;
    }
  }

  &.bottom-big {
    @include paddingMobileBottom;

    @include tablet {
      @include paddingBottomBig;
    }
  }

  &.bottom-super-big {
    @include paddingMobileBottom;

    @include tablet {
      @include paddingBottomSuper;
    }
  }

  &.bottom-small {
    @include paddingMobileBottom;

    @include tablet {
      @include paddingBottomSmallProject;
    }
  }

  &__description {
    @include paddingMobile;
    @include paddingMobileBottom;

    @include tablet {
      padding-top: 0;
      padding-right: 0;
      padding-bottom: 0;
      padding-left: 0;
    }

    h1,
    h2,
    h3 {
      padding-top: 7.7vw;
      padding-bottom: 2vw;
      font-size: 32px;
      line-height: 1.1;
      font-variation-settings: "wght" 750, "wdth" 100, "opsz" 30;
      width: 100%;

      strong {
        margin-left: -8px;
        color: $orange;
      }

      @include tablet {
        padding-bottom: 0.3vw;
        font-size: 48px;

        strong {
          margin-left: -10px;
          color: $orange;
        }
      }

      @include desktop-sm {
        font-size: 54px;
        strong {
          margin-left: -12px;
          color: $orange;
        }
      }

      @include desktop-l {
        font-size: 64px;

        strong {
          margin-left: -15px;
          color: $orange;
        }
      }

      @include desktop-xl {
        font-size: 74px;

        strong {
          margin-left: -15px;
          color: $orange;
        }
      }
    }

    em {
      strong {
        display: block;
        margin-bottom: 3vw;
        overflow: hidden;
        height: 0;

        @include tablet {
          margin-bottom: 2vw;
        }
      }
    }

    @include tablet {
      &.padding-left {
        padding-left: 5vw;
      }

      &.padding-right {
        padding-right: 5vw;
      }

      &.padding-bottom {
        padding-bottom: 5vw;
      }

      &.padding-top {
        padding-top: 5vw;
      }

      &.padding-left-big {
        padding-left: 8.5vw;
      }

      &.padding-right-big {
        padding-right: 8.5vw;
      }
    }
  }

  &__custom-description {
    @include paddingMobile;
    @include paddingMobileBottom;

    @include tablet {
      padding-top: 0;
      padding-right: 0;
      padding-bottom: 0;
      padding-left: 0;
    }

    h2 {
      padding-top: 2vw;
      font-size: 32px;
      line-height: 1.1;
      font-variation-settings: "wght" 750, "wdth" 100, "opsz" 30;
      width: 100%;

      strong {
        margin-left: -8px;
        color: $orange;
      }

      @include tablet {
        font-size: 48px;

        strong {
          margin-left: -10px;
          color: $orange;
        }
      }

      @include desktop-sm {
        font-size: 54px;
        strong {
          margin-left: -12px;
          color: $orange;
        }
      }

      @include desktop-l {
        font-size: 64px;

        strong {
          margin-left: -15px;
          color: $orange;
        }
      }

      @include desktop-xl {
         padding-top: 5vw;
        font-size: 74px;

        strong {
          margin-left: -15px;
          color: $orange;
        }
      }
    }

    h3 {
      padding-top: 2vw;
      width: 100%;
      font-size: 21px;
      line-height: 1.2;
      font-variation-settings: 'wght' 600, 'wdth' 100, 'opsz' 30;

      @include tablet {
        font-size: 23px;
      }

      @include desktop-sm {
        font-size: 28px;
      }

      @include desktop-l {
        font-size: 36px;
      }

      @include desktop-xl {
         padding-top: 5vw;
        font-size: 39px;
  }

    }

    ul {
      padding-left: 1.3vw;
    }

    li {
      padding-top: 1vw;
    }

    p {
      padding-top: 1.5vw;

      &:first-child {
        padding-top: 0;
      }
    }

    em {
      strong {
        display: block;
        margin-bottom: 3vw;
        overflow: hidden;
        height: 0;

        @include tablet {
          margin-bottom: 2vw;
        }
      }
    }

    @include tablet {
      &.padding-left {
        padding-left: 5vw;
      }

      &.padding-right {
        padding-right: 5vw;
      }

      &.padding-bottom {
        padding-bottom: 5vw;
      }

      &.padding-top {
        padding-top: 5vw;
      }

      &.padding-left-big {
        padding-left: 8.5vw;
      }

      &.padding-right-big {
        padding-right: 8.5vw;
      }
    }
  }

  &__image {
    width: 100%;

    &--item {
      width: 100%;
      height: 100%;
    }
  }

  &__pop {
    position: relative;
    width: 100%;
    cursor: pointer;

    &--image {
      width: 100%;
      height: 100%;
    }

    &--play {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 56px;
      width: auto;

      @include desktop-s {
        display: none;
      }
    }

    &--rollover {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
       @include transition;

       &:hover {
        opacity: 1;
       }
    }
  }

  &__video {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &.hide-mobile {
    display: none;

    @include tablet {
      display: flex;
    }
  }
}
</style>
