<template>
  <div id="search-wrapper">
    <div
      id="search-content"
      class="SearchResult search-scroller"
    >
      <div class="SearchResult__container">
        <div class="SearchResult__input mobile-padding">
          <!-- <SearchInputPage :value="search" /> -->
          <h2 class="SearchResult__title">
            Search results for<span class="orange">: <br>{{ search }}</span>
          </h2>
          <div
            v-if="!results"
            class="no-results"
          >
            <p class="no-results__title">
              That's a no-no amigo<span class="orange">...</span>
            </p>
            <p class="main-text no-results__subtitle">
              Better give that search luck of yours another try <br> or enjoy these other projects:
            </p>
          </div>
        </div>



        <section class="SearchResult__content">
          <div
            v-for="(result, index) in content"
            :key="`result${index}`"
            class="SearchResult__item"
          >
            <!-- {{ result }} -->
            <InertiaLink
              v-if="result.collection === 'projects'"
              class="indexProject__content"
              :href="`/work/${result.slug}/`"
            >
              <div class="indexProject__image-container">
                <img
                  v-if="result.thumbnail_4_5"
                  :data-src="getImageKitURL(result.thumbnail_4_5, 3, result.image_type,false,index,'images')"
                  :src="getImageKitURL(result.thumbnail_4_5, 3, result.image_type,true,index,'images')"
                  class="indexProject__image"
                  :alt="result.title"
                >
                <img
                  v-else
                  :src="`${$store.state.baseimageUrl}default.png`"
                  class="indexProject__image"
                  alt="default image"
                >
              </div>

              <h5 class="indexProject__client mobile-padding">
                {{ result.client_subtitle }}
              </h5>
              <h3 class="indexProject__title mobile-padding">
                {{ result.title }}
              </h3>
              <h4 class="indexProject__subtitle mobile-padding">
                {{ result.subtitle }}
              </h4>
            </InertiaLink>

            <InertiaLink
              v-else
              class="indexProject__content"
              :href="`/press/${result.slug}/`"
            >
              <div class="indexProject__image-container">
                <img
                  v-if="result.thumbnail_4_5"
                  :data-src="getImageKitURL(result.thumbnail_4_5, 3, result.image_type,false,index,'images')"
                  :src="getImageKitURL(result.thumbnail_4_5, 3, result.image_type,true,index,'images')"
                  class="indexProject__image"
                  :alt="result.title"
                >
                <img
                  v-else
                  :src="`${$store.state.baseimageUrl}default.png`"
                  class="indexProject__image"
                  alt="default image"
                >
              </div>

              <h5 class="indexProject__client mobile-padding">
                Press
              </h5>
              <h3 class="indexProject__title mobile-padding">
                {{ result.title }}
              </h3>
              <h4
                v-if="result.news_filters"
                class="indexProject__subtitle mobile-padding"
              >
                <span
                  v-for="(category, idx) in customCategory(result.news_filters)"
                  :key="category"
                >
                  <span v-if="idx > 0">, </span>
                  {{ category }}
                </span>
              </h4>
            </InertiaLink>
          </div>
        </section>
      </div>

      <span id="search-content-footer" />
      <TheFooter />
    </div>
  </div>
</template>

<script>
/* Vendor */
import { nextTick } from "vue";
import { InertiaLink } from "@inertiajs/inertia-vue3";

/* Helpers */
import { mainLayout } from "@/helpers/vue-layouts";
import seo from "@/helpers/seo";

/* Mixins */
import imageKit from "@/mixins/image-kit";
import page from "@/mixins/page";
import scrollGlobal from "@/mixins/scroll-global";
import loader from "@/mixins/loader";

export default {
  components: {
    InertiaLink
  },

  mixins: [imageKit, page, scrollGlobal, loader],

  layout: mainLayout,

  props: {
    content: {
      type: Object,
      default: () => {}
    },
    search: {
      type: String,
      default: null
    },
    results: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      // scroll animations
      bgScrollAnimation: null
    };
  },

  created() {
    seo.seoContent(
      this.content,
      `Search results for: ${this.search} - Binalogue - Global Creative & Production Company`
    );
  },

  mounted() {
    this.$nextTick(() => {
      // console.log('init images' );
      this.imagesToPreload = document.querySelectorAll("img");
      // console.log('home page imgs',this.imagesToPreload  );
      this.getBackgroundImagesUrl();
      this.loadImages();


    });

    if(this.$store.state.isMobile) {
      // alert('mobile');
      window.scrollTo(0,0);
    }


  },

  beforeUnmount() {
    this.bgScrollAnimation.scrollTrigger.kill();
  },

  methods: {
    init() {
      this.setScroller("#search-wrapper", "#search-content");
      this.toggleMenuColor("black", 0, 0);

      window.scrollTo(0,0);

      // HEADER ANIMATIONS
      this.bgScrollAnimation = this.$gsap.to(".showBgHeader", {
        scrollTrigger: {
          trigger: "#search-content",
          markers: false,
          start: "50px top",
          end: "top bottom",
          toggleActions: "play none reverse none"
        },
        height: "100%",
        duration: 0.2,
        ease: "Power2.out"
      });
    },

    customCategory(categories) {
      let returnCats = [];
      for (let i = 0; i < categories.length; i++) {
        const cat = categories[i];
        switch (cat) {
          case "awards":
            returnCats.push("Awards");
            break;
          case "news":
            returnCats.push("News");
            break;
          case "project-feature":
            returnCats.push("Project Feature");
            break;
          case "talk":
            returnCats.push("Talk");
            break;
          case "interview":
            returnCats.push("Interview");
            break;
          default:
            returnCats.push(cat);
        }
      }

      return returnCats;
    }
  }
};
</script>

<style lang="scss">
.SearchResult {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 85px;
  // flex-wrap: wrap;

  @include tablet-m {
    @include paddingPageTop;
  }


  &__title {
    font-size: 32px;
    line-height: 1.1;
    color: $grey-dark;
    letter-spacing: -0.5px;
    font-variation-settings: "wght" 700, "wdth" 100, "opsz" 30;

    @include tablet-m {
      font-size: 48px;

      br {
        display: none;
      }
    }

    @include desktop-sm {
      font-size: 54px;
      line-height: 1.25;
      letter-spacing: -0.5px;
    }

    @include desktop-l {
      font-size: 62px;
      letter-spacing: -1.5px;
    }

    .orange {
      margin-left: 2px;
      font-variation-settings: "wght" 500, "wdth" 100, "opsz" 30;
    }
  }

  &__container {
    @include paddingMedium;

    margin-bottom: 5vw;
  }

  &__input {
    margin-top: 1vw;
    margin-right: auto;
    width: 100%;
    @include marginBottom;
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  &__item {
    width: 100%;
    flex-grow: 0;
    @include marginBottomSmall;

    @include tablet {
      margin-right: 2.33%;
      width: 31.66%;

      &:nth-child(3n) {
        margin-right: 0;
      }
    }

    &--image {
      width: 100%;
      margin-bottom: 5px;

      @include tablet {
        margin-bottom: 10px;
      }
    }
  }

  .no-results {
    &__title {
      margin-top: 15px;
      margin-bottom: 15px;
      font-size: 48px;
      line-height: 1.1;
      letter-spacing: -1px;
      font-variation-settings: "wght" 800, "wdth" 100, "opsz" 30;

      @include tablet-m {
        font-size: 70px;
      }

      @include desktop-sm {
        font-size: 74px;
        line-height: 1.25;
        letter-spacing: -1.5px;
      }

      @include desktop-l {
        font-size: 84px;
        letter-spacing: -2px;
      }
    }

    &__subtitle {
      br {
        display: none;

        @include tablet {
          display: block;
        }
      }
    }
  }
}
</style>
